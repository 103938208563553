<template>
  <HeaderPages />
  <section class="section_header_full_pages">
    <div class="waves_full_pages"></div>
    <div class="container_text_full_pages">
      <h1 class="title_home">
        <span class="title_home_4">НАШИ<br /></span>
        <span class="title_home_1 color_violet"> ПРОЕКТЫ<br /></span>
      </h1>
      <div class="box_button_brifing_full_pages">
        <div class="box_button_v2">
          <button class="button_v1">Оставить заявку</button>
        </div>
      </div>
    </div>
  </section>
  <section class="section_button_filter">
    <button class="button_filter_open" @click="toggleFilter(1)">
      Отрасли
      <div class="box_img_bitton_open">
        <img
          :src="isFilterOpen === 1 ? closeImageSrc : openImageSrc"
          class="img_bitton_open"
        />
      </div>
    </button>
    <button class="button_filter_open" @click="toggleFilter(2)">
      Типы&nbsp;сайтов
      <div class="box_img_bitton_open">
        <img
          :src="isFilterOpen === 2 ? closeImageSrc : openImageSrc"
          class="img_bitton_open"
        />
      </div>
    </button>
    <button class="button_filter_open" @click="toggleFilter(3)">
      Услуги
      <div class="box_img_bitton_open">
        <img
          :src="isFilterOpen === 3 ? closeImageSrc : openImageSrc"
          class="img_bitton_open"
        />
      </div>
    </button>
    <div class="container_filter" :class="{ active: isFilterOpen === 1 }">
      <div class="box_filter">
        <button
          v-for="(button, index) in buttons"
          :key="index"
          class="filter_button"
          :class="{ active: activeButtons.includes(index) }"
          @click="handleClick(index)"
        >
          {{ button.text }}
        </button>
      </div>
    </div>
    <div class="container_filter" :class="{ active: isFilterOpen === 2 }">
      <div class="box_filter">
        <button
          v-for="(buttonV2, index) in buttonsV2"
          :key="index"
          class="filter_button"
          :class="{ active: activeButtonsV2.includes(index) }"
          @click="handleClickV2(index)"
        >
          {{ buttonV2.text }}
        </button>
      </div>
    </div>
    <div class="container_filter" :class="{ active: isFilterOpen === 3 }">
      <div class="box_filter">
        <button
          v-for="(buttonV3, index) in buttonsV3"
          :key="index"
          class="filter_button"
          :class="{ active: activeButtonsV3.includes(index) }"
          @click="handleClick(index)"
        >
          {{ buttonV3.text }}
        </button>
      </div>
    </div>
  </section>
  <SectionProjectsMain />
  <FooterPages />
</template>
<script>
import HeaderPages from "../permanent/HeaderPages.vue";
import FooterPages from "../permanent/FooterPages.vue";
import SectionProjectsMain from "../ui/SectionProjectsMain.vue";
export default {
  components: { HeaderPages, SectionProjectsMain, FooterPages },
  data() {
    return {
      activeButtons: [],
      activeButtonsV2: [],
      activeButtonsV3: [],
      buttons: [
        { text: "Все статьи" },
        { text: "Разработка" },
        { text: "Дизайн" },
        { text: "Дизайн" },
      ],
      buttonsV2: [
        { text: "Все статьи" },
        { text: "Разработка" },
        { text: "Дизайн" },
        { text: "Дизайн" },
      ],
      buttonsV3: [
        { text: "Все статьи" },
        { text: "Разработка" },
        { text: "Дизайн" },
        { text: "Дизайн" },
      ],
      openImageSrc: require("@/assets/elements/img_bitton_open.svg"),
      closeImageSrc: require("@/assets/elements/img_bitton_close.svg"),
      isFilterOpen: null,
    };
  },
  methods: {
    handleClick(index) {
      if (this.activeButtons.includes(index)) {
        // Если кнопка уже активна, удаляем ее из массива
        this.activeButtons = this.activeButtons.filter((i) => i !== index);
      } else {
        // Иначе добавляем индекс в массив активных кнопок
        this.activeButtons.push(index);
      }
      console.log("Индекс:", index);
      console.log("Текст:", this.buttons[index].text);
    },
    handleClickV2(index) {
      if (this.activeButtonsV2.includes(index)) {
        // Если кнопка уже активна, удаляем ее из массива
        this.activeButtonsV2 = this.activeButtonsV2.filter((i) => i !== index);
      } else {
        // Иначе добавляем индекс в массив активных кнопок
        this.activeButtonsV2.push(index);
      }
      console.log("Индекс:", index);
      console.log("Текст:", this.buttonsV2[index].text);
    },
    handleClickV3(index) {
      if (this.activeButtons.includes(index)) {
        // Если кнопка уже активна, удаляем ее из массива
        this.activeButtonsV3 = this.activeButtonsV3.filter((i) => i !== index);
      } else {
        // Иначе добавляем индекс в массив активных кнопок
        this.activeButtonsV3.push(index);
      }
      console.log("Индекс:", index);
      console.log("Текст:", this.buttonsV3[index].text);
    },

    toggleFilter(filterId) {
      if (this.isFilterOpen === filterId) {
        this.isFilterOpen = null; // Закрываем фильтр, если он уже открыт
      } else {
        this.isFilterOpen = filterId; // Открываем выбранный фильтр
      }
    },
  },
};
</script>
