<template>
  <section class="section_clients">
    <p class="title_services_design text_align_left color_violet">КЛИЕНТЫ</p>
    <div class="container_clients">
      <div class="box_clients">
        <img class="img_clients" src="@/assets/clients/1.svg" />
        <img class="img_clients" src="@/assets/clients/2.svg" />
        <img class="img_clients" src="@/assets/clients/3.svg" />
        <img class="img_clients" src="@/assets/clients/4.svg" />
      </div>
      <div class="box_clients">
        <img class="img_clients" src="@/assets/clients/1.svg" />
        <img class="img_clients" src="@/assets/clients/2.svg" />
        <img class="img_clients" src="@/assets/clients/3.svg" />
        <img class="img_clients" src="@/assets/clients/4.svg" />
      </div>
      <div class="box_clients">
        <img class="img_clients" src="@/assets/clients/1.svg" />
        <img class="img_clients" src="@/assets/clients/2.svg" />
        <img class="img_clients" src="@/assets/clients/3.svg" />
        <img class="img_clients" src="@/assets/clients/4.svg" />
      </div>
    </div>
    <div class="container_clients_mobil">
      <div class="box_clients">
        <img class="img_clients" src="@/assets/clients/1.svg" />
        <img class="img_clients" src="@/assets/clients/2.svg" />
        <img class="img_clients" src="@/assets/clients/3.svg" />
      </div>
      <div class="box_clients">
        <img class="img_clients" src="@/assets/clients/2.svg" />
        <img class="img_clients" src="@/assets/clients/3.svg" />
        <img class="img_clients" src="@/assets/clients/4.svg" />
      </div>
      <div class="box_clients">
        <img class="img_clients" src="@/assets/clients/1.svg" />
        <img class="img_clients" src="@/assets/clients/3.svg" />
        <img class="img_clients" src="@/assets/clients/4.svg" />
      </div>
      <div class="box_clients">
        <img class="img_clients" src="@/assets/clients/1.svg" />
        <img class="img_clients" src="@/assets/clients/2.svg" />
        <img class="img_clients" src="@/assets/clients/4.svg" />
      </div>
    </div>
  </section>
</template>
<script>
export default {
  components: {},
};
</script>
