<template>
  <div class="margin_top"></div>
  <section class="section_briefing">
    <div class="wave_footer"></div>
    <div class="container_briefing">
      <img class="img_briefing" src="@/assets/elements/briefing.png" />
      <div class="box_text_briefing">
        <p class="big_text color_white text_align_right">
          СВЯЗАТЬСЯ<br />
          С НАМИ
        </p>
        <div class="box_button_v2">
          <button class="button_v1">Оставить заявку</button>
        </div>
      </div>
    </div>
  </section>
  <footer class="section_footer">
    <div class="container_footer">
      <div class="box_button_footer">
        <p class="subtext_services_design color_violet">
          Презентация об агентстве
        </p>
        <p class="subtext_services_design color_violet">Какая-то кнопка</p>
      </div>
      <p class="subtext_services_design color_violet">© 2019-2024, MTI</p>
    </div>
  </footer>
</template>
