import { createApp } from "vue";
import App from "./App.vue";
import { createWebHistory, createRouter } from "vue-router";
import HomePages from "@/components/HomePages.vue";
import ProjectPages from "@/components/pages/ProjectPages.vue";
import BlogPages from "@/components/pages/BlogPages.vue";
import ArticleTest from "@/components/article/ArticleTest.vue";
import ProjectTest from "@/components/project/ProjectTest.vue";
import ServicesPages from "@/components/pages/ServicesPages.vue";
import SiteServices from "@/components/services/SiteServices.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: "HomePages",
      path: "/",
      component: HomePages,
    },
    {
      name: "ProjectPages",
      path: "/all_project",
      component: ProjectPages,
    },
    {
      name: "BlogPages",
      path: "/blog",
      component: BlogPages,
    },
    {
      name: "ArticleTest",
      path: "/article",
      component: ArticleTest,
    },
    {
      name: "ProjectTest",
      path: "/project",
      component: ProjectTest,
    },
    {
      name: "ServicesPages",
      path: "/services",
      component: ServicesPages,
    },
    {
      name: "SiteServices",
      path: "/site_and_services",
      component: SiteServices,
    },
  ],
});
const app = createApp(App);
createApp(App);
app.use(router);
app.mount("#app");
