<template>
  <section class="section_popular_articles">
    <div class="wave_down_v3"></div>
    <div class="container_popular_articles">
      <p class="big_text color_white">ПОПУЛЯРНЫЕ СТАТЬИ</p>
      <div class="container_all_popular_articles">
        <div class="popular_articles">
          <p class="title_services_design text_align_left color_violet">
            25 мин. &nbsp; &nbsp;<img
              class="img_eye"
              src="@/assets/elements/eye.svg"
            />
            2534
          </p>
          <p class="title_services_design text_align_left color_white">
            Визуализация данных. Как мы в MTI делаем информацию наглядной и
            понятной
          </p>
        </div>
        <div class="box_popular_articles_right">
          <div class="popular_articles">
            <p class="title_services_design text_align_left color_violet">
              25 мин. &nbsp; &nbsp;<img
                class="img_eye"
                src="@/assets/elements/eye.svg"
              />
              2534
            </p>
            <p class="title_services_design text_align_left color_white">
              Визуализация данных. Как мы в MTI делаем информацию наглядной и
              понятной
            </p>
          </div>
        </div>
        <div class="popular_articles">
          <p class="title_services_design text_align_left color_violet">
            25 мин. &nbsp; &nbsp;<img
              class="img_eye"
              src="@/assets/elements/eye.svg"
            />
            2534
          </p>
          <p class="title_services_design text_align_left color_white">
            Визуализация данных. Как мы в MTI делаем информацию наглядной и
            понятной
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="section_briefing">
    <div class="container_briefing">
      <img class="img_briefing" src="@/assets/elements/briefing.png" />
      <div class="box_text_briefing">
        <p class="big_text color_white text_align_right">
          СВЯЗАТЬСЯ<br />
          С НАМИ
        </p>
        <div class="box_button_v2">
          <button class="button_v1">Оставить заявку</button>
        </div>
      </div>
    </div>
  </section>
  <footer class="section_footer">
    <div class="container_footer">
      <div class="box_button_footer">
        <p class="subtext_services_design color_violet">
          Презентация об агентстве
        </p>
        <p class="subtext_services_design color_violet">Какая-то кнопка</p>
      </div>
      <p class="subtext_services_design color_violet">© 2019-2024, MTI</p>
    </div>
  </footer>
</template>
