<template>
  <section class="section_projects">
    <div class="wave_down"></div>
    <div class="container_projects">
      <div class="box_title_services">
        <p class="title_services_design">ПРОЕКТЫ</p>
      </div>
      <div class="contauner_all_projects">
        <div
          v-for="(project, index) in projects"
          :key="index"
          :id="'project-' + index"
          class="box_projects_animated"
        >
          <div class="box_projects">
            <div class="box_hover_img_projects">
              <div class="box_main_text_projects">
                <p class="medium_text">{{ project.mainText }}</p>
              </div>
              <div class="box_statistics_projects_hover">
                <p class="title_services_design">
                  {{ project.statistics.title }}
                </p>
                <p class="title_services_design">
                  <span class="big_text color_violet">{{
                    project.statistics.years
                  }}</span>
                  лет<br />на рынке
                </p>
              </div>
              <div class="box_href_arrow_projects">
                <div class="href_services_design_arrow">
                  <img
                    class="arrow_href_img"
                    src="@/assets/elements/arrow_href.svg"
                  />
                </div>
              </div>
              <div class="box_main_text_projects">
                <p class="medium_text">{{ project.secondText }}</p>
              </div>
              <img
                class="hover_img_projects"
                src="@/assets/background/logistics.svg"
              />
              <img
                class="hover_img_projects_weavs"
                src="@/assets/background/weves_hover.svg"
              />
            </div>
            <!-- :src="project.weavsImg.src"
            :src="project.hoverImg.src" -->
            <div class="box_text_hover">
              <p class="subtext_services_design">{{ project.subText }}</p>
              <p class="title_services_design">{{ project.title }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="box_button_circle">
        <button class="button_circle">
          Больше<br />
          проектов
          <div class="underlining_button"></div>
        </button>
      </div>
    </div>
  </section>
</template>
<script>
import { gsap } from "gsap";

export default {
  data() {
    return {
      projects: [
        {
          mainText:
            "Дизайн  Frontend  Backend  Аналитика  QA  Техподдержка  Backend  Аналитика",
          statistics: {
            title: "ПРОЕКТЫ",
            years: "10",
            text: "лет<br />на рынке",
          },
          hrefArrow: {
            src: "/img/arrow_href.6c475c2a.svg",
          },
          secondText: "Промышленная компания",
          hoverImg: {
            src: "/img/logistics.2ceb4508.svg",
          },
          weavsImg: {
            src: "/img/weves_hover.3c9b891a.svg",
          },
          subText: "Название компании название компании название компании",
          title:
            "А это самое длинное название, которое может быть, но надеюсь, его не будет.",
        },
        {
          mainText:
            "Дизайн Frontend Backend Аналитика QA Техподдержка Backend Аналитика",
          statistics: {
            title: "ПРОЕКТЫ",
            years: "10",
            text: "лет<br />на рынке",
          },
          hrefArrow: {
            src: "/img/arrow_href.6c475c2a.svg",
          },
          secondText: "Промышленная компания",
          hoverImg: {
            src: "/img/logistics.2ceb4508.svg",
          },
          weavsImg: {
            src: "/img/weves_hover.3c9b891a.svg",
          },
          subText: "Название компании название компании название компании",
          title:
            "А это самое длинное название, которое может быть, но надеюсь, его не будет.",
        },
        {
          mainText:
            "Дизайн Frontend Backend Аналитика QA Техподдержка Backend Аналитика",
          statistics: {
            title: "ПРОЕКТЫ",
            years: "10",
            text: "лет<br />на рынке",
          },
          hrefArrow: {
            src: "/img/arrow_href.6c475c2a.svg",
          },
          secondText: "Промышленная компания",
          hoverImg: {
            src: "/img/logistics.2ceb4508.svg",
          },
          weavsImg: {
            src: "/img/weves_hover.3c9b891a.svg",
          },
          subText: "Название компании название компании название компании",
          title:
            "А это самое длинное название, которое может быть, но надеюсь, его не будет.",
        },
        {
          mainText:
            "Дизайн Frontend Backend Аналитика QA Техподдержка Backend Аналитика",
          statistics: {
            title: "ПРОЕКТЫ",
            years: "10",
            text: "лет<br />на рынке",
          },
          hrefArrow: {
            src: "/img/arrow_href.6c475c2a.svg",
          },
          secondText: "Промышленная компания",
          hoverImg: {
            src: "/img/logistics.2ceb4508.svg",
          },
          weavsImg: {
            src: "/img/weves_hover.3c9b891a.svg",
          },
          subText: "Название компании название компании название компании",
          title:
            "А это самое длинное название, которое может быть, но надеюсь, его не будет.",
        },
        {
          mainText:
            "Дизайн Frontend Backend Аналитика QA Техподдержка Backend Аналитика",
          statistics: {
            title: "ПРОЕКТЫ",
            years: "10",
            text: "лет<br />на рынке",
          },
          hrefArrow: {
            src: "/img/arrow_href.6c475c2a.svg",
          },
          secondText: "Промышленная компания",
          hoverImg: {
            src: "/img/logistics.2ceb4508.svg",
          },
          weavsImg: {
            src: "/img/weves_hover.3c9b891a.svg",
          },
          subText: "Название компании название компании название компании",
          title:
            "А это самое длинное название, которое может быть, но надеюсь, его не будет.",
        },
        {
          mainText:
            "Дизайн Frontend Backend Аналитика QA Техподдержка Backend Аналитика",
          statistics: {
            title: "ПРОЕКТЫ",
            years: "10",
            text: "лет<br />на рынке",
          },
          hrefArrow: {
            src: "/img/arrow_href.6c475c2a.svg",
          },
          secondText: "Промышленная компания",
          hoverImg: {
            src: "/img/logistics.2ceb4508.svg",
          },
          weavsImg: {
            src: "/img/weves_hover.3c9b891a.svg",
          },
          subText: "Название компании название компании название компании",
          title:
            "А это самое длинное название, которое может быть, но надеюсь, его не будет.",
        },
        {
          mainText:
            "Дизайн Frontend Backend Аналитика QA Техподдержка Backend Аналитика",
          statistics: {
            title: "ПРОЕКТЫ",
            years: "10",
            text: "лет<br />на рынке",
          },
          hrefArrow: {
            src: "/img/arrow_href.6c475c2a.svg",
          },
          secondText: "Промышленная компания",
          hoverImg: {
            src: "/img/logistics.2ceb4508.svg",
          },
          weavsImg: {
            src: "/img/weves_hover.3c9b891a.svg",
          },
          subText: "Название компании название компании название компании",
          title:
            "А это самое длинное название, которое может быть, но надеюсь, его не будет.",
        },
        {
          mainText:
            "Дизайн Frontend Backend Аналитика QA Техподдержка Backend Аналитика",
          statistics: {
            title: "ПРОЕКТЫ",
            years: "10",
            text: "лет<br />на рынке",
          },
          hrefArrow: {
            src: "/img/arrow_href.6c475c2a.svg",
          },
          secondText: "Промышленная компания",
          hoverImg: {
            src: "/img/logistics.2ceb4508.svg",
          },
          weavsImg: {
            src: "/img/weves_hover.3c9b891a.svg",
          },
          subText: "Название компании название компании название компании",
          title:
            "А это самое длинное название, которое может быть, но надеюсь, его не будет.",
        },
      ],
    };
  },
  mounted() {
    const boxProjectsElements = document.querySelectorAll(
      ".box_projects_animated"
    );

    // Анимация для каждого блока
    boxProjectsElements.forEach((targetElement) => {
      const tl = gsap.timeline({ paused: true });

      const onIntersection = (entries) => {
        if (entries[0].isIntersecting) {
          tl.play();
          targetElement.removeEventListener("intersection", onIntersection);
        }
      };

      const observer = new IntersectionObserver(onIntersection, {
        threshold: 0.1,
      });

      observer.observe(targetElement);

      tl.set(targetElement, { y: "15rem", opacity: 0 });
      tl.to(targetElement, { y: "0rem", opacity: 1, duration: 1 });
    });
  },
};
</script>
<style scoped>
.box_projects_animated {
  /* Добавьте стили для начального состояния, если нужно */
  opacity: 0;
}
</style>
