<template>
  <HeaderPages />
  <section class="section_header_full_pages">
    <div class="waves_full_pages"></div>
    <div class="container_text_full_pages">
      <h1 class="title_home">
        <span class="title_home_4">БЛОГ<br /></span>
      </h1>
      <div class="box_button_brifing_full_pages">
        <div class="box_button_v2">
          <button class="button_v1">Оставить заявку</button>
        </div>
      </div>
    </div>
  </section>
  <section class="section_button_blog">
    <div class="mobail_version">
      <button class="button_filter_open" @click="toggleFilter(1)">
        Отрасли
        <div class="box_img_bitton_open">
          <img
            :src="isFilterOpen === 1 ? closeImageSrc : openImageSrc"
            class="img_bitton_open"
          />
        </div>
      </button>
    </div>
    <div class="container_filter" :class="{ active: isFilterOpen === 1 }">
      <div class="box_filter">
        <button
          v-for="(button, index) in buttons"
          :key="index"
          class="filter_button"
          :class="{ active: activeButtons.includes(index) }"
          @click="handleClick(index)"
        >
          {{ button.text }}
        </button>
      </div>
    </div>

    <div class="box_button_blog">
      <button class="button_blog">Все статьи</button>
      <button class="button_blog">Разработка</button>
      <button class="button_blog">Дизайн</button>
      <button class="button_blog">Маркетинг</button>
      <button class="button_blog">Автоматизация</button>
    </div>
    <div class="container_search_blog">
      <div @click="toggleModal" ref="box_search_blog" class="box_search_blog">
        <img class="union_search" src="@/assets/elements/Union.svg" />
      </div>
      <div ref="box_search_blog_full" class="box_search_blog_full">
        <div class="box_left_search">
          <img class="union_search" src="@/assets/elements/Union.svg" />
          <input class="input_search" placeholder="Поиск" />
        </div>
        <img
          @click="closeModal"
          class="cross_search"
          src="@/assets/elements/cross.svg"
        />
      </div>
    </div>
  </section>
  <section class="section_blog_articles">
    <div class="box_big_articles">
      <img src="@/assets/background/articles.png" class="big_img_articles" />
      <p class="font_1rem_400_Raleway color_violet">10 июля 2024</p>
      <p class="title_services_design">
        {{ articlesData[0].title }}
      </p>
    </div>
    <div class="container_right_newsletter">
      <div class="box_right_newsletter">
        <div class="text_newsletter">
          <p class="title_services_design">Подпишись на нашу рассылку</p>
          <p class="font_1rem_400_Raleway color_violet">
            Мы отправляем полезные<br />
            материалы, которые помогут<br />
            вам в работе
          </p>
        </div>
        <div class="box_input_newsletter">
          <input v-model="email" class="input_newsletter" placeholder="Email" />
          <button class="button_newsletter" @click="handleSubmit">
            {{ isEmailValid ? "" : "ОК" }}
            <img
              v-if="isEmailValid"
              src="@/assets/elements/check_mark.svg"
              class="check_mark"
            />
          </button>
          <p v-if="showErrorMessage" class="error-message">
            Некорректный Email*
          </p>
        </div>
      </div>
      <div class="box_articles">
        <img src="@/assets/background/articles.png" class="img_articles" />
        <p class="font_1rem_400_Raleway color_violet">
          {{ articlesData[1].date }}
        </p>
        <p class="input_search">
          {{ articlesData[1].title }}
        </p>
      </div>
    </div>
    <div
      v-for="(article, index) in articlesData.slice(2)"
      :key="index"
      class="box_articles full_version"
    >
      <img src="@/assets/background/articles.png" class="img_articles" />
      <p class="font_1rem_400_Raleway color_violet">
        {{ article.date }}
      </p>
      <p class="input_search">
        {{ article.title }}
      </p>
    </div>
    <div class="box_right_newsletter mobail_version">
      <div class="text_newsletter">
        <p class="font_2_18_mobil">Подпишись на нашу рассылку</p>
        <p class="font_1rem_400_Raleway color_violet">
          Мы отправляем полезные<br />
          материалы, которые помогут<br />
          вам в работе
        </p>
      </div>
      <div class="box_input_newsletter">
        <input v-model="email" class="input_newsletter" placeholder="Email" />
        <button class="button_newsletter" @click="handleSubmit">
          {{ isEmailValid ? "" : "ОК" }}
          <img
            v-if="isEmailValid"
            src="@/assets/elements/check_mark.svg"
            class="check_mark"
          />
        </button>
        <p v-if="showErrorMessage" class="error-message">Некорректный Email*</p>
      </div>
    </div>

    <div
      v-for="(article, index) in articlesData.slice(0)"
      :key="index"
      class="box_articles mobail_version"
    >
      <img src="@/assets/background/articles.png" class="img_articles" />
      <p class="font_1rem_400_Raleway color_violet">
        {{ article.date }}
      </p>
      <p class="input_search">
        {{ article.title }}
      </p>
    </div>
    <div class="box_margin_button_v3">
      <div class="box_button_v3">
        <button class="button_v1">БОЛЬШЕ СТАТЕЙ</button>
      </div>
    </div>
  </section>
  <FooterPages />
</template>
<script>
import HeaderPages from "../permanent/HeaderPages.vue";
import FooterPages from "../permanent/FooterPages.vue";
import { ref } from "vue";
export default {
  components: { HeaderPages, FooterPages },
  setup() {
    const email = ref("");
    const isEmailValid = ref(false);
    const showErrorMessage = ref(false);

    const validateEmail = () => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      isEmailValid.value = emailRegex.test(email.value);
    };
    const articlesData = ref([
      {
        date: "10 июля 2024",
        title:
          "Форматно-логический контроль. Зачем он нужен бизнесу и как его систематизировать.",
        image: "@/assets/background/articles.png",
      },
      {
        date: "10 июля 2024",
        title:
          "Название предыдущей статьи название предыдущей статьи название предыдущей статьи",
      },
      {
        date: "10 июля 2024",
        title:
          "Название предыдущей статьи название предыдущей статьи название предыдущей статьи",
      },
      {
        date: "10 июля 2024",
        title:
          "Название предыдущей статьи название предыдущей статьи название предыдущей статьи",
      },
      {
        date: "10 июля 2024",
        title:
          "Название предыдущей статьи название предыдущей статьи название предыдущей статьи",
      },
      {
        date: "10 июля 2024",
        title:
          "Название предыдущей статьи название предыдущей статьи название предыдущей статьи",
      },
      {
        date: "10 июля 2024",
        title:
          "Название предыдущей статьи название предыдущей статьи название предыдущей статьи",
      },
      {
        date: "10 июля 2024",
        title:
          "Название предыдущей статьи название предыдущей статьи название предыдущей статьи",
      },

      // ... Добавьте еще статьи
    ]);

    const handleSubmit = () => {
      validateEmail(); // Проверяем email перед отправкой
      if (isEmailValid.value) {
        console.log("Email:", email.value); // Отправляем email в консоль
        showErrorMessage.value = false; // Скрываем сообщение об ошибке
      } else {
        showErrorMessage.value = true; // Покажем сообщение об ошибке
      }
    };

    return {
      email,
      isEmailValid,
      showErrorMessage,
      validateEmail,
      handleSubmit,
      articlesData,
    };
  },
  methods: {
    toggleModal() {
      this.$refs.box_search_blog_full.classList.toggle("active");
      this.$refs.box_search_blog.classList.toggle("active");
    },
    handleClick(index) {
      if (this.activeButtons.includes(index)) {
        // Если кнопка уже активна, удаляем ее из массива
        this.activeButtons = this.activeButtons.filter((i) => i !== index);
      } else {
        // Иначе добавляем индекс в массив активных кнопок
        this.activeButtons.push(index);
      }
      console.log("Индекс:", index);
      console.log("Текст:", this.buttons[index].text);
    },
    closeModal() {
      this.$refs.box_search_blog_full.classList.toggle("active");
      this.$refs.box_search_blog.classList.toggle("active");
    },
    toggleFilter(filterId) {
      if (this.isFilterOpen === filterId) {
        this.isFilterOpen = null; // Закрываем фильтр, если он уже открыт
      } else {
        this.isFilterOpen = filterId; // Открываем выбранный фильтр
      }
    },
  },
  data() {
    return {
      activeButtons: [],
      buttons: [
        { text: "Все статьи" },
        { text: "Разработка" },
        { text: "Дизайн" },
        { text: "Дизайн" },
      ],
      openImageSrc: require("@/assets/elements/img_bitton_open.svg"),
      closeImageSrc: require("@/assets/elements/img_bitton_close.svg"),
      isFilterOpen: null,
    };
  },
};
</script>
