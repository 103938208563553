<template>
  <RouterView />
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>
<style>
@import "@/assets/css/reset.css";
@import "@/assets/css/init.css";
</style>
