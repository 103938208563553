<template>
  <HeaderPages />
  <section class="section_header_full_pages">
    <div class="waves_full_pages"></div>
    <div class="container_text_full_pages">
      <h1 class="title_home">
        <span class="title_home_4">УСЛУГИ<br /></span>
      </h1>
      <div class="box_button_brifing_full_pages">
        <div class="box_button_v2">
          <button class="button_v1">Оставить заявку</button>
        </div>
      </div>
    </div>
  </section>
  <section class="sectio_services_pages">
    <div class="container_services_pages_main">
      <div class="container_services_pages">
        <div class="box_title_services_pages">
          <div class="box_text_services_pages">
            <p class="title_services_design">Сайты и сервисы</p>
            <p class="subtext_services_design">
              Создаем удобные и эффективные сайты, которые нравятся пользователя
              и хорошо продвигаются в поисковых системах.
            </p>
          </div>
        </div>
        <ul class="list_services subtext_services_design">
          <li>Корпоративные сайты</li>
          <li>Онлайн-сервисы</li>
          <li>E-commerce</li>
          <li>Порталы</li>
          <li>Карьерные сайты</li>
        </ul>
        <ul class="list_services subtext_services_design">
          <li>Корпоративные сайты</li>
          <li>Онлайн-сервисы</li>
          <li>E-commerce</li>
          <li>Порталы</li>
          <li>Карьерные сайты</li>
        </ul>
      </div>
      <button class="button_blog">Подробнее</button>
    </div>
    <div class="container_services_pages_main bg_blue">
      <div class="container_services_pages">
        <div class="box_title_services_pages">
          <div class="box_text_services_pages">
            <p class="title_services_design">Дизайн</p>
            <p class="subtext_services_design">
              Сервисный дизайн на основе аналитики, метрик и пользовательского
              опыта — продуктовый подход для создания привлекательных и
              эффективных решений.
            </p>
          </div>
        </div>
        <ul class="list_services subtext_services_design">
          <li>UX/UI дизайн</li>
          <li>Дизайн -системы</li>
          <li>Брендинг</li>
        </ul>
        <ul class="list_services subtext_services_design">
          <li>3D-визуализация</li>
          <li>Графический дизайн</li>
          <li>Дизайн-поддержка</li>
        </ul>
      </div>
      <button class="button_blog">Подробнее</button>
    </div>
    <div class="container_services_pages_main">
      <div class="container_services_pages">
        <div class="box_title_services_pages">
          <div class="box_text_services_pages">
            <p class="title_services_design">Аналитика</p>
            <p class="subtext_services_design">
              Изучаем бизнес клиента и его конкурентов, подбираеминструменты и
              способы продвижения.
            </p>
          </div>
        </div>
        <ul class="list_services subtext_services_design">
          <li>UX-аналитика</li>
          <li>Веб-аналитика</li>
          <li>Исследования</li>
        </ul>
      </div>
      <button class="button_blog">Подробнее</button>
    </div>
    <div class="container_services_pages_main bg_blue">
      <div class="container_services_pages">
        <div class="box_title_services_pages">
          <div class="box_text_services_pages">
            <p class="title_services_design">SEO-продвижение</p>
            <p class="subtext_services_design">
              Повышаем поток посетителей на сайте за счет органического трафика
              и снижаем затраты на привлечение клиентов.
            </p>
          </div>
        </div>
        <ul class="list_services subtext_services_design">
          <li>SEO на этапе разработки сайта</li>
          <li>SEO-продвижение</li>
          <li>SEO-поддержка</li>
        </ul>
      </div>
      <button class="button_blog">Подробнее</button>
    </div>
    <div class="container_services_pages_main full_version">
      <div class="container_services_pages">
        <div class="box_title_services_pages">
          <div class="box_text_services_pages">
            <p class="title_services_design">Поддержка и развитие</p>
            <p class="subtext_services_design">
              Обеспечиваем бесперебойную работу сайта, оказываем
              контент-поддержку, модернизируем текущий функционал и создаем
              новый.
            </p>
            <ul class="list_services subtext_services_design">
              <li>Базовая техническая поддержка</li>
              <li>Сопровождение</li>
              <li>Модернизация</li>
            </ul>
            <button class="button_blog">Подробнее</button>
          </div>
        </div>
        <div class="box_title_services_pages">
          <p class="title_services_design">SERM / ORM</p>

          <p class="subtext_services_design">
            Управляем репутацией персон, компаний,<br />
            брендов в онлайн-среде.
          </p>

          <div class="box_row_sb">
            <ul class="list_services subtext_services_design">
              <li>HR-портал</li>
              <li>CRM</li>
            </ul>
            <ul class="list_services subtext_services_design">
              <li>Проектный офис</li>
              <li>Автоматизация</li>
            </ul>
          </div>
          <button class="button_blog">Подробнее</button>
        </div>
      </div>
    </div>
    <div class="container_services_pages_main mobail_version">
      <div class="container_services_pages">
        <div class="box_title_services_pages">
          <div class="box_text_services_pages">
            <p class="title_services_design">Поддержка и развитие</p>
            <p class="subtext_services_design">
              Обеспечиваем бесперебойную работу сайта, оказываем
              контент-поддержку, модернизируем текущий функционал и создаем
              новый.
            </p>
            <ul class="list_services subtext_services_design">
              <li>Базовая техническая поддержка</li>
              <li>Сопровождение</li>
              <li>Модернизация</li>
            </ul>
          </div>
        </div>
      </div>
      <button class="button_blog">Подробнее</button>
    </div>
    <div class="container_services_pages_main mobail_version bg_blue">
      <div class="box_title_services_pages">
        <p class="title_services_design">SERM / ORM</p>
        <p class="subtext_services_design">
          Управляем репутацией персон, компаний,<br />
          брендов в онлайн-среде.
        </p>

        <div class="box_row_sb">
          <ul class="list_services subtext_services_design">
            <li>HR-портал</li>
            <li>CRM</li>
          </ul>
          <ul class="list_services subtext_services_design">
            <li>Проектный офис</li>
            <li>Автоматизация</li>
          </ul>
        </div>
      </div>
      <button class="button_blog">Подробнее</button>
    </div>
  </section>
  <section class="section_schemes">
    <p class="title_services_design color_violet">СХЕМЫ РАБОТЫ</p>
    <div class="container_schemes">
      <div class="box_schemes margin_schemes">
        <p class="title_services_design pos_schemes_v1">Fix Price</p>
        <img src="@/assets/background/Fix_Price.png" class="img_schemes" />
        <p class="subtext_services_design pos_schemes_v2">
          Для задач с предсказуемыми<br />
          сроком и бюджетом.
        </p>
      </div>
      <div class="box_schemes">
        <p class="title_services_design pos_schemes_v1">Time & materials</p>
        <img src="@/assets/background/Time.png" class="img_schemes" />
        <p class="subtext_services_design pos_schemes_v3">
          Для длинных и сложных<br />
          проектов с гибким <br />
          планированием.
        </p>
      </div>
      <div class="box_schemes margin_schemes">
        <p class="title_services_design pos_schemes_v1">Fix Price</p>
        <img src="@/assets/background/Retainer.png" class="img_schemes" />
        <p class="subtext_services_design pos_schemes_v3">
          Выделенная команда на<br />
          определенный срок<br />
          по зафиксированной цене.
        </p>
      </div>
    </div>
  </section>
  <SectionBlog />
  <SectionClients />
  <FooterPages />
</template>
<script>
import HeaderPages from "@/components/permanent/HeaderPages.vue";
import FooterPages from "@/components/permanent/FooterPages.vue";
import SectionBlog from "@/components/ui/SectionBlog.vue";
import SectionClients from "@/components/ui/SectionClients.vue";

export default {
  components: {
    HeaderPages,
    SectionBlog,
    SectionClients,
    FooterPages,
  },
};
</script>
