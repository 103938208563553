<template>
  <HeaderPages />
  <section class="section_home">
    <div class="box_home_right_waves"></div>
    <div class="home_circle_v1"></div>
    <div class="home_circle_v2"></div>
    <div class="box_home_main">
      <div class="poziton_relative">
        <h1 class="title_home">
          <span class="title_home_1">ДИЗАЙН И<br /></span>
          <span class="title_home_2 color_violet"> РАЗРАБОТКА<br /></span>
          <span class="title_home_3">ЦИФРОВЫХ<br /></span>
          <span class="title_home_4">СЕРВИСОВ</span>
        </h1>
        <div class="box_right_button">
          <div class="box_button_v2_header">
            <button class="button_v1">Оставить заявку</button>
          </div>
        </div>
        <div class="box_right_text">
          <p class="medium_text text-align_left">
            B2B и D2C e-commerce, Личные кабинеты,<br />
            Информационные системы
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="section_services_home">
    <div class="max_white">
      <div class="wave"></div>
      <div class="bevel"></div>
      <div class="white_background"></div>
      <div class="container_left_services_home">
        <div class="container_services_design services_design_1">
          <div class="box_services_design_text">
            <p class="title_services_design text_align_left">Дизайн</p>
            <p class="subtext_services_design text_align_left">
              Разработаем интерфейс на основе аналитики, метрик и
              пользовательского опыта
            </p>
          </div>
          <div class="href_services_design_arrow">
            <img
              class="arrow_href_img"
              src="@/assets/elements/arrow_href.svg"
            />
          </div>
        </div>
        <div class="container_services_design services_design_5">
          <div class="box_services_design_text">
            <p class="title_services_design text_align_left">Сайт под ключ</p>
            <p class="subtext_services_design text_align_left">
              Разработаем интерфейс на основе аналитики, метрик и
              пользовательского опыта
            </p>
            <p class="subtext_services_design text_align_left">
              Разработаем интерфейс на основе аналитики, метрик и
              пользовательского опыта
            </p>
            <p class="subtext_services_design text_align_left">
              Разработаем интерфейс на основе аналитики, метрик и
              пользовательского опыта
            </p>
          </div>
          <div class="href_services_design_arrow">
            <img
              class="arrow_href_img"
              src="@/assets/elements/arrow_href.svg"
            />
          </div>
        </div>
      </div>
      <div class="container_left_services_home">
        <div class="container_services_design services_design_2">
          <div class="box_services_design_text">
            <p class="title_services_design text_align_left">Дизайн</p>
            <p class="subtext_services_design text_align_left">
              Разработаем интерфейс на основе аналитики, метрик и
              пользовательского опыта
            </p>
            <p class="subtext_services_design text_align_left">
              Разработаем интерфейс на основе аналитики, метрик и
              пользовательского опыта
            </p>
          </div>
          <div class="href_services_design_arrow">
            <img
              class="arrow_href_img"
              src="@/assets/elements/arrow_href.svg"
            />
          </div>
        </div>
        <div class="container_services_design services_design_6">
          <div class="box_services_design_text">
            <p class="title_services_design text_align_left">SEO</p>
            <p class="subtext_services_design text_align_left">
              Разработаем интерфейс на основе аналитики, метрик и
              пользовательского опыта
            </p>
            <p class="subtext_services_design text_align_left">
              Разработаем интерфейс на основе аналитики, метрик и
              пользовательского опыта
            </p>
          </div>
          <div class="href_services_design_arrow">
            <img
              class="arrow_href_img"
              src="@/assets/elements/arrow_href.svg"
            />
          </div>
        </div>
      </div>
      <div class="container_left_services_home">
        <div class="container_services_design services_design_3">
          <div class="box_services_design_text">
            <p class="title_services_design text_align_left">Сайты и сервисы</p>
            <p class="subtext_services_design text_align_left">
              Разработаем интерфейс на основе аналитики, метрик и
              пользовательского опыта
            </p>
          </div>
          <div class="href_services_design_arrow">
            <img
              class="arrow_href_img"
              src="@/assets/elements/arrow_href.svg"
            />
          </div>
        </div>
        <div class="container_services_design services_design_7">
          <div class="box_services_design_text">
            <p class="title_services_design text_align_left">Сайт под ключ</p>
            <p class="subtext_services_design text_align_left">
              Разработаем интерфейс на основе аналитики, метрик и
              пользовательского опыта
            </p>
            <p class="subtext_services_design text_align_left">
              Разработаем интерфейс на основе аналитики, метрик и
              пользовательского опыта
            </p>
          </div>
          <div class="href_services_design_arrow">
            <img
              class="arrow_href_img"
              src="@/assets/elements/arrow_href.svg"
            />
          </div>
        </div>
        <div class="container_services_design services_design_9">
          <div class="box_services_design_text">
            <p class="title_services_design text_align_left">Сайты и сервисы</p>
            <p class="subtext_services_design text_align_left">
              Разработаем интерфейс на основе аналитики
            </p>
          </div>
          <div class="href_services_design_arrow">
            <img
              class="arrow_href_img"
              src="@/assets/elements/arrow_href.svg"
            />
          </div>
        </div>
      </div>
      <div class="container_left_services_home">
        <div class="container_services_design services_design_4">
          <img class="img_bot_mti" src="@/assets/elements/mti.png" />
        </div>
        <div class="container_services_design services_design_8">
          <div class="box_services_design_text">
            <p class="title_services_design text_align_left">SEO</p>
            <p class="subtext_services_design text_align_left">
              Разработаем интерфейс на основе аналитики, метрик и
              пользовательского опыта
            </p>
          </div>
          <div class="href_services_design_arrow">
            <img
              class="arrow_href_img"
              src="@/assets/elements/arrow_href.svg"
            />
          </div>
        </div>
      </div>
      <div class="container_button_services">
        <div class="box_button_v1">
          <button class="button_v1">УСЛУГИ</button>
        </div>
      </div>
    </div>
  </section>
  <SectionExperience />
  <SectionProjects />
  <SectionClients />
  <SectionBlog />
  <FooterArticles />
</template>

<script>
import HeaderPages from "./permanent/HeaderPages.vue";
import FooterArticles from "./permanent/FooterArticles.vue";
import SectionExperience from "./ui/SectionExperience.vue";
import SectionProjects from "./ui/SectionProjects.vue";
import SectionClients from "./ui/SectionClients.vue";
import SectionBlog from "./ui/SectionBlog.vue";
import { gsap } from "gsap";
export default {
  components: {
    HeaderPages,
    SectionExperience,
    SectionProjects,
    SectionBlog,
    SectionClients,
    FooterArticles,
  },
  mounted() {
    const maxWhiteElement = document.querySelector(".max_white");
    const tl = gsap.timeline({ paused: true });

    const onIntersection = (entries) => {
      if (entries[0].isIntersecting) {
        tl.play();
        maxWhiteElement.removeEventListener("intersection", onIntersection);
      }
    };

    const observer = new IntersectionObserver(onIntersection, {
      threshold: 0.1,
    });

    observer.observe(maxWhiteElement);

    // Анимация для первых 4 элементов с классом services_design
    for (let i = 1; i <= 4; i++) {
      const targetElement = document.querySelector(`.services_design_${i}`);
      tl.set(targetElement, { y: "5rem", opacity: 0 }, `<+${(i - 1) * 0.1}`);
      tl.to(targetElement, { y: "0rem", opacity: 1, duration: 0.2 }, "<");
    }

    // Цикл для остальных элементов с классом services_design
    for (let i = 5; i <= 9; i++) {
      const targetElement = document.querySelector(`.services_design_${i}`);
      tl.set(targetElement, { y: "5rem", opacity: 0 }, `<+${(i - 5) * 0.1}`);
      tl.to(targetElement, { y: "0rem", opacity: 1, duration: 0.2 }, "<");
    }

    // Анимация для container_button_services
    const targetElement10 = document.querySelector(
      ".container_button_services"
    );
    tl.set(targetElement10, { y: "3rem", opacity: 0 }, "<+0.7"); // Без задержки
    tl.to(targetElement10, { y: "0rem", opacity: 1, duration: 0.7 }, "<");
  },
};
</script>
