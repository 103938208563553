<template>
  <section class="section_blog_home_pages">
    <div class="container_blog_home_pages">
      <div class="box_title_services_v2">
        <p class="title_services_design text_align_left color_violet">Блог</p>
      </div>
      <div class="container_all_blog_home_pages">
        <div
          v-for="(article, index) in articles"
          :key="index"
          class="box_blog_home_pages"
        >
          <p class="subtext_services_design color_violet">{{ article.date }}</p>
          <p class="subtext_services_design">{{ article.text }}</p>
        </div>
      </div>
      <div class="container_button_circle_v2">
        <div class="box_button_circle_v2" ref="buttonCircle">
          <button class="button_circle">
            Больше<br />
            проектов
            <div class="underlining_button"></div>
          </button>
        </div>
      </div>
      
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";

export default {
  data() {
    return {
      articles: [
        {
          date: "10 июля 2024",
          text: "Визуализация данных. Как мы в MTI делаем информацию наглядной и понятной Визуализация данных. Как мы в MTI делаем информацию наглядной и понятной",
        },
        {
          date: "10 июля 2024",
          text: "Визуализация данных. Как мы в MTI делаем информацию наглядной и понятной Визуализация данных. Как мы в MTI делаем информацию наглядной и понятной",
        },
        {
          date: "10 июля 2024",
          text: "Визуализация данных. Как мы в MTI делаем информацию наглядной и понятной Визуализация данных. Как мы в MTI делаем информацию наглядной и понятной",
        },
      ],
    };
  },
  mounted() {
    const containerBlogElement = document.querySelector(
      ".container_blog_home_pages"
    );
    const blogArticlesElements = document.querySelectorAll(
      ".box_blog_home_pages"
    );
    const buttonCircleElement = this.$refs.buttonCircle;

    const tl = gsap.timeline({ paused: true });

    const onIntersection = (entries) => {
      if (entries[0].isIntersecting) {
        tl.play();
        containerBlogElement.removeEventListener(
          "intersection",
          onIntersection
        );
      }
    };

    const observer = new IntersectionObserver(onIntersection, {
      threshold: 0.1,
    });

    observer.observe(containerBlogElement);

    blogArticlesElements.forEach((targetElement) => {
      tl.set(targetElement, { y: "5rem", opacity: 0 });
      tl.to(targetElement, {
        y: "0rem",
        opacity: 1,
        duration: 0.4,
      });
    });

    // Анимация для кнопки
    tl.set(buttonCircleElement, { y: "5rem", opacity: 0 });
    tl.to(buttonCircleElement, {
      y: "0rem",
      opacity: 1,
      duration: 0.5,
    });
  },
};
</script>

<style scoped>
.box_blog_home_pages {
  /* Добавьте стили для начального состояния, если нужно */
  opacity: 0;
}

.box_button_circle_v2 {
  /* Добавьте стили для начального состояния, если нужно */
  opacity: 0;
}
</style>
