<template>
  <header>
    <div @mousemove="onMouseMove" ref="header_section" class="header_section">
      <div ref="container_header" class="container_header">
        <RouterLink to="/"
          ><img class="logo_header" src="@/assets/logo/logo.svg"
        /></RouterLink>
        <div ref="box_button_nav_header" class="box_button_nav_header">
          <RouterLink to="/" class="text_small_kreadon-bold"
            >о mti dev</RouterLink
          >
          <RouterLink to="/all_project" class="text_small_kreadon-bold"
            >проекты</RouterLink
          >
          <RouterLink to="/blog" class="text_small_kreadon-bold"
            >блог</RouterLink
          >
          <RouterLink to="/" class="text_small_kreadon-bold">услуги</RouterLink>
        </div>
        <div class="box_button_nav">
          <div class="box_button_burger" @click="toggleModal">
            <div ref="button_burger_line_1" class="button_burger_line_1"></div>
            <div ref="button_burger_line_2" class="button_burger_line_2"></div>
            <div ref="button_burger_line_3" class="button_burger_line_3"></div>
          </div>
          <div class="box_button_v1_header">
            <button class="button_v1">Оставить заявку</button>
          </div>
          <div class="box_button_eng_header">
            <div class="button_eng_header">eng</div>
          </div>
        </div>
      </div>
      <div ref="content_burger" class="content_burger">
        <div class="left_nav_burger">
          <div class="box_nav_burger">
            <RouterLink
              to="/"
              @click="closeModal"
              class="text_burger_nav text_align_right"
              >о mti dev</RouterLink
            >
            <RouterLink
              to="/Project"
              @click="closeModal"
              class="text_burger_nav text_align_right"
              >проекты</RouterLink
            >
            <RouterLink
              to="/blog"
              @click="closeModal"
              class="text_burger_nav text_align_right"
              >блог</RouterLink
            >
            <RouterLink
              to="/"
              @click="closeModal"
              class="text_burger_nav text_align_right"
              >услуги</RouterLink
            >
            <RouterLink
              to="/"
              @click="closeModal"
              class="text_burger_nav text_align_right"
              >контакты</RouterLink
            >
          </div>
          <img class="search_button" src="@/assets/elements/search.svg" />
        </div>
        <div class="right_nav_burger">
          <p class="text_small_kreadon-bold">youtube</p>
          <p class="text_small_kreadon-bold">telegram</p>
          <p class="text_small_kreadon-bold">vkontakte</p>
          <div class="box_box_animation" @mousemove="onMouseMove">
            <img
              ref="face_bot_burger"
              class="face_bot_burger"
              src="@/assets/elements/face_mti.png"
            />
            <img class="body_bot_burger" src="@/assets/elements/body_mti.png" />
          </div>
        </div>
        <div class="box_button_burger_mobil">
          <div class="box_button_v2">
            <button class="button_v1">Оставить заявку</button>
          </div>
          <div class="box_button_eng_burger">
            <div class="button_eng_header">eng</div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      mouseX: 0,
      mouseY: 0,
      face_bot_burger: null,
      maxRight: 1.9,
      minRight: 1.5,
      maxTop: 0.7,
      minTop: 0.2,
    };
  },
  mounted() {
    this.face_bot_burger = this.$refs.face_bot_burger;
  },
  methods: {
    toggleModal() {
      this.$refs.box_button_nav_header.classList.toggle("active");
      this.$refs.content_burger.classList.toggle("active");
      this.$refs.header_section.classList.toggle("active");
      this.$refs.container_header.classList.toggle("active");
      this.$refs.button_burger_line_1.classList.toggle("active");
      this.$refs.button_burger_line_2.classList.toggle("active");
      this.$refs.button_burger_line_3.classList.toggle("active");
      document.body.classList.toggle("no-scroll");
    },
    closeModal() {
      this.$refs.box_button_nav_header.classList.toggle("active");
      this.$refs.content_burger.classList.remove("active");
      this.$refs.header_section.classList.remove("active");
      this.$refs.container_header.classList.remove("active");
      this.$refs.button_burger_line_1.classList.remove("active");
      this.$refs.button_burger_line_2.classList.remove("active");
      this.$refs.button_burger_line_3.classList.remove("active");
      document.body.classList.remove("no-scroll");
    },
    onMouseMove(event) {
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
    },
  },
  watch: {
    mouseX(mouseX) {
      // Используйте mouseX напрямую
      const right =
        this.maxRight -
        (mouseX / window.innerWidth) * (this.maxRight - this.minRight);
      this.face_bot_burger.style.right = `${right}rem`;
    },
    mouseY(mouseY) {
      // Используйте mouseY напрямую
      const top =
        this.minTop +
        (mouseY / window.innerHeight) * (this.maxTop - this.minTop);
      this.face_bot_burger.style.top = `${top}rem`;
    },
  },
};
</script>
